import { PureComponent } from 'react'

import errorHandler from './error-handler'

export const ZendeskAPI = (...args) => {
  if (typeof window === 'undefined') {
    console.error('Attempting to use client-side Zendesk API on the server')
    return
  } else if (!window.zE) {
    console.error('Failed to load necessary 3rd-party script: Zendesk chat widget')
    errorHandler(new Error('Failed to load necessary 3rd-party script: Zendesk chat widget'))
    return
  }
  window.zE(...args)
}

type PropTypes = {
  email: string
  displayName: string
  mobilePhone: string
  isLoggedIn: boolean
  isMobile: boolean
}

export default class ZendeskChat extends PureComponent<PropTypes> {
  sendData() {
    const { email, displayName, mobilePhone, isLoggedIn, isMobile } = this.props

    // only show the widget on desktop for logged-in users
    if (isLoggedIn && !isMobile) {
      ZendeskAPI('webWidget', 'show')
      if (email) {
        ZendeskAPI('webWidget', 'identify', {
          name: displayName,
          email: email,
          phone: mobilePhone
        })
      }
    } else {
      ZendeskAPI('webWidget', 'hide')
    }
  }

  componentWillUnmount() {
    ZendeskAPI('webWidget', 'hide')
  }

  componentDidUpdate() {
    this.sendData()
    return false
  }

  render() {
    return null
  }
}
